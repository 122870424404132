import React, { useState } from "react";
import moment from 'moment';
import 'moment/locale/fr';

import classes from './Calendar.module.css';

const Calendar = () => {

    moment.locale('fr');
    const [refDate, setRefDate] = useState({date: moment()});

    console.log(refDate)
    const createDaysOfMonth = (dateRef) => {

        const date = moment(dateRef).endOf('month');

        const lastDate = date.date();
        const firstWeekday = date.startOf('month').day();

        const calendarDays = [];

        const today = moment();

        const regularClass = [ classes.Box, classes.Day].join(' ');
        const todayClass = [ classes.Box, classes.Day, classes.Today].join(' ');
        const greyClass = [ classes.Box, classes.Day, classes.GreyOut].join(' ');

        for (let w=firstWeekday; w>0; w--) {

          const dateNumber = moment(date).startOf('month').subtract(w, 'days').get('date');
          calendarDays.push(<div className={greyClass} key={Math.random()}>{dateNumber} </div>); // empty days
        }

        for (let d=1; d<=lastDate; d++) {
            const assignedClass = date.date(d).isSame(today, 'day') ? todayClass : regularClass;
            calendarDays.push(<div className={assignedClass} key={d}>{d}</div>);
        }

        let extraDay = 0;
        while (calendarDays.length % 7 !== 0) {
            extraDay = extraDay +1;
            const dateNumber = moment(date).add(extraDay, 'days').get('date');
            calendarDays.push(<div className={greyClass} key={Math.random()}> {dateNumber} </div>);
        }

        return calendarDays;
    }

    const prevMonth = () => {
      setRefDate({date: refDate.date.subtract(1, 'month')});
    }

    const nextMonth = () => {
      setRefDate({date: refDate.date.add(1, 'month')});
    }

    return (
      <div className={classes.CalendarWrapper} >
        <div className={classes.Header} >
          <button className={classes.MonthButton} onClick={prevMonth}>&lt;</button>
          {refDate.date.format('MMMM YYYY')}
          <button className={classes.MonthButton} onClick={nextMonth}>&gt;</button>
        </div>


        <div className={classes.CalendarContainer} >
          <div className={[classes.DayHeader, classes.Box].join(' ')}>Dimanche</div>
          <div className={[classes.DayHeader, classes.Box].join(' ')}>Lundi</div>
          <div className={[classes.DayHeader, classes.Box].join(' ')}>Mardi</div>
          <div className={[classes.DayHeader, classes.Box].join(' ')}>Mercredi</div>
          <div className={[classes.DayHeader, classes.Box].join(' ')}>Jeudi</div>
          <div className={[classes.DayHeader, classes.Box].join(' ')}>Vendredi</div>
          <div className={[classes.DayHeader, classes.Box].join(' ')}>Samedi</div>
            {createDaysOfMonth(refDate.date)}
        </div>

      </div>
    );
};

export default Calendar;
